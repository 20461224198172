import React from "react"
import Page from "../../components/page"
import TextLeft from "../../components/textLeft"
import { graphql } from "gatsby"

export default function AlbumsShipping({ location, data }) {
  return (
    <Page title={"Shipping – PhotoTailors"} location={location}>
      <TextLeft
        title={<div>SHIPPING</div>}
        subtitle="worldwide"
        text={
          <div>
            <div>
              We offer FREE worldwide express shipping for every album. Production time is 7 working days from the
              moment you’ve placed your order or since you’ve approved the design layout.
            </div>
            <br/>
            <div style={{fontWeight: 'bold'}}>Shipping time:</div>
            <br/>

            <ul style={{marginLeft: '20px'}}>
              <li>Europe: 1-3 working days</li>
              <li>USA & Canada: 2-4 working days</li>
              <li>Australia & Asia: 3-8 working days</li>
              <li>South America: 3-8 working days</li>
            </ul>

          </div>
        }
        data={data}
        image={"albumsShippingImage"}
      />


    </Page>
  )
}


export const query = graphql`
  query {
    albumsShippingImage: file(relativePath: { eq: "albumsShippingImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
  
   
  }
`